<!-- 房间主题管理-->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="title"
        placeholder="请输入主题"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>
      <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column type="index" label="ID" width="60"> </el-table-column>
      <el-table-column prop="title" label="主题" width="130"> </el-table-column>
      <!-- <el-table-column prop="area" label="面积(平方)" width="100">
      </el-table-column> -->
      <el-table-column prop="image" label="图片" width="80">
        <template slot-scope="scope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scope.row.image"
            alt="this.form"
          />
        </template>
      </el-table-column>
      <el-table-column prop="volume" label="适合人数" width="180">
      </el-table-column>
      <el-table-column prop="s_name" label="店铺名" width="180">
      </el-table-column>

      <el-table-column prop="operate" label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="success" @click="mod(scope.row)"
            >编辑</el-button
          >
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination
    ><el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <!-- <el-form-item label="账号" prop="no">
          <el-col :span="20">
            <el-input v-model="form.no"></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="主题" prop="title">
          <el-col :span="20">
            <el-input v-model="form.title"></el-input>
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="面积(平方)" prop="area">
          <el-col :span="20">
            <el-input v-model="form.area"></el-input>
          </el-col>
        </el-form-item> -->
        <el-form-item label="图片" prop="image">
          <el-col :span="20">
            <imgUploader
              :iga="form.image"
              ref="pic2"
              :width="200"
              :height="200"
              :limit="1"
              @change="img()"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="适合人数" prop="volume">
          <el-col :span="20">
            <el-input v-model="form.volume"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="店铺名" prop="s_name">
          <el-col :span="20">
            <el-input v-model="form.s_name" disabled></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
    
    <script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  name: "StorageManage",
  components: {
    imgUploader,
  },
  data() {
    let checkvolume = (rule, value, callback) => {
      if (value > 150) {
        callback(new Error("人数输入过大"));
      } else {
        callback();
      }
    };
    return {
      tableData: [
        {
          id: 1,
          title: "万亿达",
          area: "156",
          volume: 1,
          number: "10",
          s_name: "撒旦法剧本杀",
          s_id: 2,
          image: "图片",
        },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      title: this.form,
      centerDialogVisible: false,
      form: {
        image: [],
        id: "",
        title: "",
        area: 0,
        volume: 0,
        s_name: this.$store.getters.getMenu.s_name,
        s_id: this.$store.getters.getMenu.s_id,
      },
      rules: {
        image: [{ required: true, message: "请选择图片", trigger: "blur" }],
        title: [{ required: true, message: "请输入主题", trigger: "blur" }],
        // area: [{ required: true, message: "请输入面积", trigger: "blur" }],
        volume: [
          { required: true, message: "请输入适合人数", trigger: "blur" },
          { min: 1, max: 3, message: "长度在 1 到 2 个位", trigger: "blur" },
          {
            pattern: /^([1-9][0-9]*){1,2}$/,
            message: "人数必须为正整数字",
            trigger: "blur",
          },
          { validator: checkvolume, trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入主持场数", trigger: "blur" },
        ],
        s_name: [{ required: true, message: "请输入店铺名", trigger: "blur" }],
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    }, // 编辑商品
    mod(row) {
      this.centerDialogVisible = true;
      this.form.image = [];
      this.form.id = row.id;
      this.form.title = row.title;
      this.form.area = row.area;
      this.form.volume = row.volume;
      this.form.s_name = row.s_name;
      this.form.s_id = row.s_id;
      this.form.image.push({
        base64: this.$httpUrl + "/image/" + row.image,
      });
    },
    add() {
      this.centerDialogVisible = true;
      this.form.image = [];
      this.form.id = "";
      this.form.title = "";
      this.form.area = "";
      this.form.volume = 0;
      this.form.s_name = this.$store.getters.getMenu.s_name;
      this.form.s_id = this.$store.getters.getMenu.s_id;
    },
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/room/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = this.form;
    },
    loadPost() {
      this.tableData = [];
      this.$axios
        .post(this.$httpUrl + "/room/pagelist", {
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          param: {
            title: this.title,
          },
        })
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.tableData = res.data;
          } else {
            alert("获取数据失败");
          }
        });
    },
    save() {
      console.log(this.form.image);
      if (this.form.volume == undefined || this.form.title == undefined) {
        this.$message({
          message: "请输入必填项",
          type: "warning",
        });
      } else if (this.form.image == [] || this.form.image == undefined) {
        this.$message({
          message: "请选择图片",
          type: "warning",
        });
      }
      let a = {
        id: this.form.id,
        name: this.form.name,
        area: this.form.area,
        title: this.form.title,
        image: this.form.image[0].base64,
        volume: this.form.volume,
        s_name: this.form.s_name,
        s_id: this.form.s_id,
      };
      this.$axios
        .post(this.$httpUrl + "/room/addUpdate/", a)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.loadPost();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
  },
};
</script>
    
    <style scoped>
</style>